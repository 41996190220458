<template>
  <div
    v-if="userStationName"
    class="section-concurrent">
    <div class="section-header">
      <h1 class="title">
        <i class="fas fa-clock icon" /> Realtime Concurrent User
      </h1>
      <div class="subtitle">
        {{currentDate}}
      </div>
    </div>
    <div class="section-body">
      <PreLoaderSection
        class="mb-4"
        :options="{ 'height': 92 }"
        v-if="isLoading" />
      <EmptyMessage
        class="ccu-empty-container"
        v-if="!isLoading & !hasData"
        dark>
        <h5 slot="title" class="title">ไม่พบยอดชม Realtime ของสถานีนี้</h5>
      </EmptyMessage>
      <SectionInfoCard
        v-if="hasData"
        :formatted-data="formattedCCUData" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import { numberWithCommas } from '@/utils/numberUtils';
import { loadRealtimeCCU } from '@/report/api';
// import {}

import PreLoaderSection from '@/components/PreLoaderSection.vue';
import SectionInfoCard from '@/components/SectionInfoCard.vue';
import EmptyMessage from '@/components/EmptyMessage.vue';
import { getStationDisplayName } from '@/utils/stationUtils';
import { getTableHeaderByName } from '@/utils/tableUtils';

export default {
  props: {
    formattedData: {
      type: Array,
    },
  },
  components: {
    EmptyMessage,
    SectionInfoCard,
    PreLoaderSection,
  },
  data() {
    return {
      ccuData: {},
      currentDate: '',
      formattedCCUData: [],
      isLoading: true,
      isError: null,
      updateIntervalRef: null,
    };
  },
  computed: {
    ...mapGetters(['userStationName']),
    filter() {
      return {
        includes: 'entries',
        stationName: this.userStationName,
      };
    },
    hasData() {
      return !this.isLoading && !this.isError;
    },
  },
  created() {
    this.startUpdateInterval();
    this.getCCUData();
  },
  watch: {
    userStationName() {
      if (this.userStationName) {
        this.getCCUData();
      }
    },
  },
  methods: {
    numberWithCommas,
    startUpdateInterval() {
      if (this.isError) {
        return;
      }

      if (this.updateIntervalRef) {
        clearInterval(this.updateIntervalRef);
      }

      this.updateIntervalRef = setInterval(async () => {
        await this.getCCUData();
      }, 60000);
    },
    async getCCUData() {
      this.currentDate = format(new Date(), 'dd/MM/yyyy HH:mm');
      try {
        const response = await loadRealtimeCCU(this.filter);
        this.ccuData = response.data;
        this.mapCCUData();
        this.isLoading = false;
        this.isError = null;
      } catch (error) {
        this.isLoading = false;
        this.isError = _.get(error, 'message', error);
      }
    },
    mapCCUData() {
      const concurrent = _.get(this.ccuData, 'concurrent', 0);
      const ccuSchema = [
        {
          name: 'Total Concurrent',
          value: this.numberWithCommas(concurrent),
          className: '',
        },
      ];

      this.ccuData.entries.forEach((data) => {
        const stationName = getStationDisplayName(_.get(data, 'stationName', ''));
        const platform = getTableHeaderByName(_.get(data, 'platform', ''));
        const displayName = `${stationName} (${platform})`;

        ccuSchema.push({
          name: displayName,
          value: this.numberWithCommas(data.viewCount) || 0,
          className: data.name,
          platformName: data.platform,
        });
      });
      this.formattedCCUData = ccuSchema;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/page.scss';

.ccu-empty-container {
  height: 112px;
  min-height: 112px;
}

.section-concurrent {
  padding-top: $spacer * 2;

  .section-header {
    display: flex;
    align-items: center;
    color: $gray-800;
    padding-bottom: $spacer * 0.75;

    .icon {
      margin-right: $spacer * 0.5;
    }

    .title {
      color: $body-color-on-dark;
      padding-bottom: 0;
      font-weight: bold;
      font-size: $font-size-base * 1.75;
      margin-bottom: 0;
    }

    .subtitle {
      color: $body-color;
      margin-left: $spacer;
      font-size: $font-size-base * 1.25;
    }

    .action {
      margin-left: auto;
    }
  }

  &:first-child {
    padding-top: $spacer * 2;
  }
}

@media (max-width: 575.98px) {
  .section-concurrent {
    flex-direction: column;

    .section-header {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      .icon {
        margin-right: $spacer * 0.25;
      }
      .title {
        font-size: 20px;
      }
      .subtitle {
        margin-top: $spacer * 0.25;
        margin-left: 0;
      }

    }
  }
}
</style>
