<template>
  <div class="page summary-container">
    <template v-if="isStationLoading">
      <div class="container loading-container">
        <PreLoaderSection />
      </div>
    </template>
    <template v-if="!isStationLoading && !isStationError">
      <SectionManageStation />
      <ReportDetails />
    </template>
    <template v-if="!isStationLoading && isStationError">
      <EmptyMessage>
        <h1 slot="title" class="title">ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้</h1>
      </EmptyMessage>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';

import SectionManageStation from '@/report/SectionManageStation.vue';
import ReportDetails from '@/report/ReportDetails.vue';
import EmptyMessage from '@/components/EmptyMessage.vue';
import PreLoaderSection from '@/components/PreLoaderSection.vue';

export default {
  components: {
    PreLoaderSection,
    EmptyMessage,
    ReportDetails,
    SectionManageStation,
  },
  data() {
    return {
      currentStation: '',
    };
  },
  computed: {
    ...mapState({
      stationList: store => store.user.stationList,
      stationError: store => store.user.stationError,
    }),
    ...mapGetters(['userStationName']),
    isStationLoading() {
      return _.isEmpty(this.stationList);
    },
    isStationError() {
      return !this.isStationLoading && this.userStationName && this.stationError;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/page.scss';

.loading-container {
  margin-top: $spacer;
}
</style>
