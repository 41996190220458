<template>
  <Section class="summary-total-chart-container">
    <div
      class="section-header"
      slot="header">
      <h1 class="title">รายการยอดชมสูงสุด</h1>
      <div class="summary-type action" v-if="topPrograms">
        <button
          type="button"
          @click.stop="exportCSV"
          class="btn btn-primary btn-export-csv mr-2">
          <span><i class="fas fa-download mr-2"></i>Export CSV</span>
        </button>
        <select
          class="custom-select"
          v-model="sortBy">
          <option value="totalViewCount" selected disabled hidden>Sort By</option>
          <option
            v-for="(value, index) in sortedKeys"
            :value="value"
            :key="index">
            {{getTableHeaderByName(value)}}
          </option>
          <option value="datetimenew">Date & Time (Newest)</option>
          <option value="datetimeold">Date & Time (Oldest)</option>
        </select>
      </div>
    </div>
    <div class="section-body top-list">
      <table class="table table-borderless table-hover">
        <tr class="table-row">
          <th
            v-for="(header, index) in tableHeader"
            :key="index"
            scope="col"
            :class="{'header-ccu': index > 1}"
            class="header desktop-cell">
            <i
              :style="{color: getTableHeaderColorByName(header.name)}"
              v-if="shouldShowIcon(header.name)"
              class="fas fa-circle" />
            {{getTableHeaderByName(header.name)}}
          </th>
        </tr>
        <tr
          class="table-row"
          v-for="(list, listIndex) in formattedTopListData"
          :key="listIndex">
          <th class="header desktop-cell" scope="row">
            <p class="rank">{{listIndex+1}}</p>
          </th>
          <td class="name desktop-cell">
            <div :to="`program/${list.clientRefId}`" class="title">{{list.title}}</div>
            <div class="time">{{formatTableDate(list.startTime, list.endTime)}}</div>
          </td>
          <td
            class="platform-ccu desktop-cell"
            v-for="(header, index) in tableHeader.slice(2, 5)"
            :key="index">
            {{getViewCountFromKey(header.name, list.entries)}}
          </td>
          <td class="total-ccu desktop-cell">{{list.totalViewCount}}</td>
          <td class="mobile-ccu-container mobile-cell">
            <div class="mobile-station-info">
              <p class="mobile-rank">{{listIndex+1}}</p>
              <div class="info-group">
                <p class="program-name">{{list.title}}</p>
                <p class="program-time">{{formatTableDate(list.startTime, list.endTime)}}</p>
              </div>
            </div>
            <div class="total-ccu-row">
              <span class="platform-name">
                Total:
              </span>
              <span class="platform-value">
                {{list.totalViewCount}}
              </span>
            </div>
            <div
              class="mobile-platform-ccu"
              v-for="(header, index) in tableHeader.slice(2, 5)"
              :key="index">
              <span class="platform-name">
                {{getTableHeaderByName(header.name)}}
              </span>
              <span class="platform-value">
                {{getViewCountFromKey(header.name, list.entries)}}
              </span>
            </div>
            <div class="spacer"></div>
          </td>
        </tr>
      </table>
    </div>
  </Section>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Section from '@/components/Section.vue';
import { getTableHeaderByName, getTableHeaderColorByName } from '@/utils/tableUtils';
import { getDownloadCSVLink } from '@/report/api';
import { formatTableDate } from '@/utils/dateFormatter';
import { generateLink } from '@/utils/generateLink';
import { numberWithCommas } from '@/utils/numberUtils';

export default {
  props: {
    topPrograms: {
      type: Object,
    },
    sortedKeys: {
      type: Array,
    },
  },
  components: {
    Section,
  },
  data() {
    return {
      tableHeader: [],
      tableData: null,
      formattedTopListData: [],
      sortBy: 'totalViewCount',
    };
  },
  computed: {
    ...mapGetters(['userStationName']),
    filter() {
      return {
        range: this.$route.query.range || null,
        startDate: this.$route.query.startDate || null,
        endDate: this.$route.query.endDate || null,
        sortBy: this.sortBy,
      };
    },
  },
  created() {
    this.initTableData();
  },
  watch: {
    topPrograms() {
      this.initTableData();
    },
    sortBy() {
      this.sortMaxCCUByKey(this.sortBy);
    },
  },
  methods: {
    formatTableDate,
    generateLink,
    numberWithCommas,
    getTableHeaderByName,
    getTableHeaderColorByName,
    initTableData() {
      const header = _.get(this.topPrograms, 'header', null);
      this.tableData = _.get(this.topPrograms, 'datas', null);
      this.tableHeader = _.filter(header, h => h.name !== 'stationName' && h.name !== 'official');
      this.formattedTopListData = _.cloneDeep(this.topPrograms);
      this.sortMaxCCUByKey(this.sortBy);
    },
    shouldShowIcon(name) {
      return name === 'web' || name === 'app' || name === 'trueId';
    },
    sortMaxCCUByKey(keyName) {
      let sortedData = [];
      const data = _.get(this.topPrograms, 'datas', null);
      if (keyName === undefined) {
        sortedData = _.orderBy(data, ['totalViewCount'], ['desc']);
      }
      if (keyName === 'datetimenew') {
        sortedData = data.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
      } else if (keyName === 'datetimeold') {
        sortedData = _.reverse(data.sort((a, b) => new Date(b.startTime) - new Date(a.startTime)));
      } else {
        sortedData = _.orderBy(data, [keyName], ['desc']);
      }
      this.mapAndSetFormattedData(sortedData);
    },
    mapAndSetFormattedData(sortedData) {
      const entries = sortedData.map((detail, index) => detail.entries.map((data, index2) => ({
        ...data,
        viewCount: this.numberWithCommas(sortedData[index].entries[index2].viewCount),
      })));

      this.formattedTopListData = sortedData.map(
        (detail, index) => ({
          ...detail,
          totalViewCount: this.numberWithCommas(detail.totalViewCount),
          entries: entries[index],
        }),
      );

      this.$emit('table-ready');
    },
    async exportCSV() {
      const teamName = _.get(this.$route, 'params.teamName', null);
      const stationName = this.userStationName;
      const filter = {
        ...this.filter,
        teamName,
        stationName,
      };
      const exportData = await getDownloadCSVLink(filter);
      const downloadURL = _.get(exportData, 'data.downloadURL', '#');
      window.open(downloadURL, '_blank');
    },
    getViewCountFromKey(key, entries) {
      const result = _.find(entries, { name: key });

      return result ? result.viewCount : '-';
    },
  },
};
</script>


<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/page.scss';
@import '~@/assets/scss/themes/components/table.scss';

</style>
