<template>
  <div
    :class="{'dark': dark}"
    class="multiple-select-container">
    <multiselect
      :value="value"
      :options="options"
      :multiple="multiple"
      :placeholder="placeholder"
      :taggable="taggable"
      :label="label"
      :track-by="trackBy"
      :selected-label="selectedLabel"
      :select-label="selectLabel"
      :deselect-label="deselectLabel"
      :tag-placeholder="tagPlaceholder"
      :show-labels="multiple"
      :disabled="disabled"
      @tag="addNewTag"
      @select="onSelected"
      @remove="onRemoved"
      @search-change="onSearchChange"
      open-direction="bottom">
      <slot></slot>
      <template slot="option" slot-scope="props">
        <div class="option">
          <div class="option__desc">
            <span class="option__title">{{ props.option[label] }}</span>
          </div>
        </div>
      </template>
      <span slot="noResult">
        {{noResultText}}
      </span>
    </multiselect>
  </div>
</template>

<script>
import _ from 'lodash';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: 'เลือกรายการด้านล่าง หรือพิมพ์คำค้นหา',
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'name',
    },
    selectedLabel: {
      type: String,
      default: 'เลือกแล้ว',
    },
    selectLabel: {
      type: String,
      default: 'เลือก',
    },
    deselectLabel: {
      type: String,
      default: 'นำออก',
    },
    tagPlaceholder: {
      type: String,
      default: 'แท็ก',
    },
    trackBy: {
      type: String,
      default: 'name',
    },
    addTag: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    noResultText: {
      type: String,
      default: 'ไม่พบตัวเลือกที่ค้นหา',
    },
  },
  methods: {
    addNewTag(selectedOption) {
      const body = { name: selectedOption };
      this.addTag(body);
    },
    onSelected(selectedOption) {
      this.$emit('onChanged', [...this.value, selectedOption]);
    },
    onRemoved(selectedOption) {
      const filterTags = _.filter(this.value, tag => tag[this.trackBy] !== selectedOption[this.trackBy]);
      this.$emit('onChanged', filterTags);
    },
    onSearchChange(q) {
      this.$emit('onSearch', { q });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
.option {
  max-width: 100%;
  white-space: initial;
}

.option__desc {
  max-width: 80%;
}

::v-deep .multiselect__single, ::v-deep .multiselect__input, ::v-deep .multiselect__single {
  margin-bottom: 0;
  margin-top: 2px;
}

.dark {
  ::placeholder {
    color: #B6B8BA;
  }

  *::placeholder {
    color: #B6B8BA;
  }

  ::v-deep .multiselect__tags {
    background-color: $primary-bg-lighter;
    border: none;
  }

  ::v-deep .multiselect__single, ::v-deep .multiselect__input, .multiselect__single {
    background-color: $primary-bg-lighter;
    color: #FFF;
    font-weight: 700;
  }

  ::v-deep .multiselect__select:before {
    color: #B6B8BA;
    border-color: #B6B8BA transparent transparent;
  }

  ::v-deep .multiselect__content-wrapper {
    border: none;
    background-color: $primary-bg-lighter;
    color: #FFF;
  }

  ::v-deep .multiselect__option--selected,
  ::v-deep .multiselect__option--selected.multiselect__option--highlight,
  ::v-deep .multiselect__option--highlight {
    background: #484E55;
    color: #FFF;
  }
}
</style>
