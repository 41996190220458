<template>
  <div class="filter-container">
    <div class="filter" v-if="!isDatepickerDisabled()">
      <router-link
        :to="getPageURL(range)"
        class="filter-item no-link"
        :class="{'active': isRangeDateSelected(range)}"
        v-for="(range, index) in customTimePeriod"
        :key="index">{{range.name}}</router-link>
      <DatePicker
        :presentLinkFunction="getPageURL"
        :customTimePeriod="customTimePeriod"
        :dateRange="dateRange"
        :onChange="onSelectDateFromCalendar" />
    </div>
    <!-- only case disabled custom date, need to split component because mobile experience -->
    <div class="filter" v-if="isDatepickerDisabled()">
      <router-link
        :to="getPageURL(range)"
        class="filter-item fitler-show-preset no-link"
        :class="{'active': isRangeDateSelected(range)}"
        v-for="(range, index) in customTimePeriod"
        :key="index">{{range.name}}</router-link>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { generateLink } from '@/utils/generateLink';
import DatePicker from '@/components/DatePicker.vue';

export default {
  props: {
    disabledRangeDateKey: {
      type: Array,
      default: () => [], // e.g. ['forver', 'last24hours', 'custom'];
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      selectedFilter: null,
      defaultRangeDate: null,
      customTimePeriod: [
        {
          name: 'Last 24 Hours',
          key: 'last24hours',
        },
        {
          name: 'Last 7 Days',
          key: 'last7days',
        },
        {
          name: 'Last 30 Days',
          key: 'last30days',
        },
        {
          name: 'Today',
          key: 'today',
        },
        {
          name: 'Forever',
          key: 'forever',
        },
      ],
      customRangeDate: {
        startDate: null,
        endDate: null,
      },
    };
  },
  created() {
    this.customTimePeriod = this.enableRangeDate(
      this.customTimePeriod,
      this.disabledRangeDateKey,
    );
    this.defaultRangeDate = _.head(this.customTimePeriod);
  },
  computed: {
    dateRange() {
      const { range, startDate, endDate } = this.$route.query;
      return { range, startDate, endDate };
    },
  },
  methods: {
    generateLink,
    enableRangeDate(customTimePeriod, disabledRangeDateKey) {
      if (disabledRangeDateKey && disabledRangeDateKey.length === 0) {
        return customTimePeriod;
      }
      return _.filter(
        customTimePeriod, rangeDateItem => !this.isRangeDateDisabled(rangeDateItem, disabledRangeDateKey),
      );
    },
    isDatepickerDisabled() {
      return _.find(this.disabledRangeDateKey, item => item === 'custom');
    },
    isRangeDateDisabled(rangeDateItem, disabledRangeDateKey) {
      return _.find(disabledRangeDateKey, item => item === rangeDateItem.key);
    },
    getPageURL(rangeDate) {
      const oldQuery = _.assign(_.cloneDeep(this.$route.query), {
        startDate: null,
        endDate: null,
      });
      return this.generateLink(this.$route.path, oldQuery, {
        range: rangeDate.key,
      });
    },
    isRangeDateSelected(rangeDate) {
      if (
        !this.dateRange.range && !this.dateRange.startDate && !this.dateRange.endDate
      ) {
        return rangeDate.key === this.defaultRangeDate.key;
      }

      if (!this.dateRange.startDate && !this.dateRange.endDate) {
        return rangeDate.key === this.dateRange.range;
      }
      return this.dateRange.range === rangeDate.key;
    },
    onSelectDateFromCalendar(rangeDate) {
      const newRageDate = {
        range: null,
        startDate: rangeDate.startDate,
        endDate: rangeDate.endDate,
      };
      const query = this.generateLink(
        this.$route.path,
        this.$route.query,
        newRageDate,
      );
      this.$router.push(query).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/scss/variables.scss';

.filter {
  display: flex;
  margin-left: auto;

  .filter-item {
    padding: $spacer * 0.5 $spacer * 0.75;
    border-radius: $border-radius-base;
    margin: 0 $spacer * 0.25;
    cursor: pointer;
    background-color: white;
    color: #656A70;
    border: 1px solid #eeeeee;

    &.active {
      font-weight: 600;
    }

    &.active, &:hover {
      border-color: $primary;
      color: $primary;
    }

    &:last-child {
      margin-right: 0;
    }

    &.filter-item-date-range {
      .calendar-icon {
        margin-left: $spacer * 0.5;
      }

      .remove {
        opacity: 0.75;
        color: $primary;
        margin-left: $spacer * 0.5;
        transition: all 0.2s ease-out;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .filter {
    .filter-item {
      display: none;

      &.fitler-show-preset {
        display: block;
      }
    }
  }
}
</style>
