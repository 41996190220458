<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    shadow: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/scss/variables.scss';

.card {
  background: #ffffff;
  border-radius: 5px;
  border: none;
}

.card-shadow {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}
</style>
