<template>
  <div class="card-container">
    <div class="card-list">
      <Card class="card-info left-border total-ccu">
        <div class="card-title">
          {{getPlatformLabel(totalCCU)}}
        </div>
        <div class="card-info-body">
          {{getPlatformValue(totalCCU)}}
        </div>
      </Card>
      <Card
        v-for="(platform, index) in platformData"
        :key="index"
        :style="{color: getPlatformColorCode(platform.platformName)}"
        :class="`card-info left-border ${platform.className}`">
        <div class="card-title">
          {{getPlatformLabel(platform)}}
        </div>
        <div class="card-info-body">
          {{getPlatformValue(platform)}}
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import { getPlatformColorCode } from '@/utils/colorUtils';

import _ from 'lodash';
import Card from '@/components/Card.vue';

export default {
  props: {
    formattedData: {
      type: Array,
    },
  },
  components: {
    Card,
  },
  computed: {
    platformData() {
      return this.formattedData.slice(1);
    },
    totalCCU() {
      return _.head(this.formattedData);
    },
  },
  methods: {
    getPlatformColorCode,
    getPlatformLabel(dataObject) {
      return _.get(dataObject, 'name', '');
    },
    getPlatformValue(dataObject) {
      return _.get(dataObject, 'value', 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';

.card-container {
  display: flex;

  .card-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .card-info {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 0 21.2%;
    margin-bottom: $spacer;
    padding: $spacer * 3 $spacer * 3;
    margin-right: $spacer;

    .card-title {
      position: absolute;
      top: $spacer / 2;
      left: $spacer / 2;
      display: flex;
      align-self: flex-end;
      font-size: $font-size-sm;
      margin-bottom: 0;
      margin-left: auto;
      color: #bdbdbd;
    }

    .card-info-body {
      font-size: $font-size-lg * 2;
      color: $gray-800;
      line-height: 1;
      margin-top: auto;
      margin-left: auto;
      position: absolute;
      right: $spacer;
      bottom: $spacer;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .total-ccu {
    flex: 0 0 32%;
  }

  .left-border {
    border-left: 7px solid;
  }
}

@media (min-width: 1024px) and (max-width: 1199.98px) {
  .card-container {
    .card-info {
      padding: $spacer * 3 $spacer * 3;
      margin-right: $spacer * 0.7;
      .card-info-body {
        font-size: $font-size-lg * 1.5;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    .total-ccu {
      font-size: $font-size-lg * 1.5;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .card-container {
    .card-info {
      padding: $spacer * 2.5 $spacer * 3;
      margin-right: $spacer * 0.65;
      .card-info-body {
        font-size: $font-size-lg * 1.25;
        bottom: $spacer / 2;
        right: $spacer / 2;
      }
      &.total-ccu {
        flex: 0 0 30%;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .card-container {
    display: unset;
    .card-list {
      display: flex;
      flex: 1;
    }
    .card-info {
      padding: $spacer * 2.5 $spacer * 3.5;
      flex: 0 0 100%;
      width: 100%;
      .card-info-body {
        font-size: $font-size-lg * 1.5;
      }
    }
  }
}

@media (max-width: 480px) {
  .card-container {
    .card-info {
      flex: 0 0 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      padding: $spacer $spacer * 1.5 $spacer $spacer;
      .card-title, .card-info-body {
        position: static;
        margin: 0;
        align-self: unset;
      }
      .card-title {
        font-size: $font-size-lg;
      }
    }
  }
}
</style>
