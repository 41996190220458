import { render, staticRenderFns } from "./SectionInfoCard.vue?vue&type=template&id=767c1d80&scoped=true&"
import script from "./SectionInfoCard.vue?vue&type=script&lang=js&"
export * from "./SectionInfoCard.vue?vue&type=script&lang=js&"
import style0 from "./SectionInfoCard.vue?vue&type=style&index=0&id=767c1d80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "767c1d80",
  null
  
)

export default component.exports