<template>
  <div class="section-summary-graph">
    <div class="section-header summary-header">
      <h1 class="title">
        <i class="fas fa-chart-bar icon" /> Summary
      </h1>
      <div class="action">
        <FilterDate :disabledRangeDateKey="['forever', 'today']" />
      </div>
    </div>
    <EmptyMessage v-if="isSeriesEmpty">
      <img slot="icon" src="@/assets/image/graph_icon.svg">
      <h1 slot="title" class="title">ไม่พบยอดชมรายการในช่วงเวลาดังกล่าว</h1>
    </EmptyMessage>
    <Section
      v-if="!isSeriesEmpty"
      class="summary-total-chart-container">
      <div class="section-header" slot="header">
        <h1 class="title">Total Summary</h1>
      </div>
      <div class="section-body">
        <LineChart
          title=""
          :series="series"
          :categories="categories"
          :colors="colors" />
      </div>
    </Section>
  </div>
</template>

<script>
/* eslint-disable */

import _ from 'lodash';
import { transformDateToDisplay } from '@/utils/dateFormatter';
import { getStationDisplayName } from '@/utils/stationUtils';
import { getTableHeaderByName } from '@/utils/tableUtils';
import { getPlatformColorCode } from '@/utils/colorUtils';

import Section from '@/components/Section.vue';
import FilterDate from '@/components/FilterDate.vue';
import EmptyMessage from '@/components/EmptyMessage.vue';
import LineChart from '@/components/LineChart.vue';

export default {
  props: {
    graphData: {
      type: Object,
    },
  },
  components: {
    LineChart,
    EmptyMessage,
    Section,
    FilterDate,
  },
  data() {
    return {
      series: [],
      categories: [],
      colors: [],
    };
  },
  computed: {
    isSeriesEmpty() {
      return _.isEmpty(this.series);
    },
    isCategoriesEmpty() {
      return _.isEmpty(this.categories);
    },
  },
  watch: {
    graphData() {
      this.series = null;
      this.categories = null;
      this.initSeries();
      this.initCategories();
    },
  },
  created() {
    this.initSeries();
    this.initCategories();
  },
  methods: {
    transformDateToDisplay,
    initSeries() {
      const allFirstPeriod = this.graphData.viewCounts.map((type) => type.data[0]);
      const sortedTime = _.sortBy(allFirstPeriod, 'date');
      const earliestStartTime = sortedTime[0].date;

      Array.prototype.move = function (from, to) {
        this.splice(to, 0, this.splice(from, 1)[0]);
      };

      let viewCounts = _.get(this.graphData, 'viewCounts', []);

      if (viewCounts.length === 3) {
        // Original array order: app, trueId, web
        // app, trueId, web -> app, web, trueId
        viewCounts.move(1, 2);
        // app, web, trueId -> web, app, trueId
        viewCounts.move(0, 1);
      }

      this.series = _.chain(viewCounts)
        .map((source) => {
          const data = _.get(source, 'data.0', null);
          const fillSource = this.fillData(source.data, earliestStartTime);
          const innerData = _.map(fillSource, (innerSource) => innerSource.viewCount);

          const stationName = getStationDisplayName(_.get(data, 'stationName', ''));
          const platform = getTableHeaderByName(_.get(data, 'platform', ''));
          const displayName = `${stationName} (${platform})`;
          this.colors.push(getPlatformColorCode(platform));

          return {
            data: innerData,
            name: displayName,
          };
        })
        .value();
      this.$emit('graph-ready', this.series);
    },
    initCategories() {
      const dateArray = _.map(this.graphData.viewCounts, (list) => _.map(list.data, (data) => data.date));
      this.categories = _.head(dateArray);
    },
    fillData(sourceData, startTime) {
      let filledData = null;

      filledData = this.checkLostDataAtMiddle(sourceData);
      filledData = this.checkLostDataAtStart(filledData, startTime);
      return filledData;
    },
    checkLostDataAtMiddle(sourceData) {
      const filledData = _.cloneDeep(sourceData);
      sourceData.forEach((period, index) => {
        const nextPeriodData = sourceData[index + 1];
        if (nextPeriodData) {
          const thisPeriodDate = new Date(period.date);
          const nextPeriodDate = new Date(nextPeriodData.date);
          const diffTime = nextPeriodDate - thisPeriodDate;
          // If difference time more than 15 minutes, Mock new data.
          if (diffTime > this.intervalTime) {
            // Number of mock data that we need to add.
            const numberOfLost = (diffTime / this.intervalTime) - 1;
            for (let i = 1; i <= numberOfLost; i += 1) {
              const time = nextPeriodDate.getTime();
              const newDate = new Date(time + (this.intervalTime * i));
              const mockUp = {
                ...period,
                date: newDate,
                viewCount: null,
              };
              // Add new data to next index.
              filledData.splice(index + 1, 0, mockUp);
            }
          }
        }
      });
      return filledData;
    },
    checkLostDataAtStart(sourceData, startTime) {
      const filledData = _.cloneDeep(sourceData);
      const firstPeriod = sourceData[0];

      const earliestStartTime = new Date(startTime);
      const firstPeriodTime = new Date(firstPeriod.date);
      // Check difference time between start of the graph and start time of this line.
      const diffTime = firstPeriodTime - earliestStartTime;

      if (diffTime > this.intervalTime) {
        // Number of mock data that we need to add.
        const numberOfLost = (diffTime / this.intervalTime);
        for (let i = 1; i <= numberOfLost; i += 1) {
          const newDate = new Date((this.intervalTime * i));
          const mockUp = {
            ...firstPeriod,
            date: newDate,
            viewCount: null,
          };
          // Add new data to next index.
          filledData.splice(i - 1, 0, mockUp);
        }
      }
      return filledData;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/page.scss';

::v-deep .chart-container {
  padding: 0;
}

::v-deep .time {
  padding-left: 0;
  padding-top: 0;
}

.section-container {
  .summary-header {
    position: relative;
    .title {
      font-size: $font-size-base * 1.8;
    }
  }
}

@media screen and (max-width: 575.98px) {
  .section .section-body {
    padding: $spacer * 0.25;
  }
  ::v-deep .time {
    padding-top: $spacer * 0.85;
    padding-left: $spacer * 0.85;
    padding-bottom: $spacer * 0.5;
  }
}
</style>
