<template>
  <div class="container">
    <template v-show="isGraphReady && isTableReady">
      <div class="section-container">
        <PreLoaderSection
          v-if="isGraphDataLoading" />
        <SectionReportGraph
          v-if="!isGraphDataLoading"
          @graph-ready="onGraphReady"
          :graph-data="graphData" />
      </div>
      <div class="section-container">
        <div class="section-summary-graph">
          <PreLoaderSection
            v-if="isTableDataLoading" />
          <SectionReportList
            v-if="!isTableDataLoading && !isEmptyTopPrograms"
            :top-programs="topPrograms.topPrograms"
            :sorted-keys="sortedKeys"
            @table-ready="onTableReady" />
          <EmptyMessage v-if="isEmptyTopPrograms">
            <img slot="icon" src="@/assets/image/table_icon.svg">
            <h1 slot="title" class="title">ไม่พบยอดชมรายการในช่วงเวลาดังกล่าว</h1>
          </EmptyMessage>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable arrow-parens */
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { loadGraphData, loadTopList } from '@/report/api';

import SectionReportGraph from '@/report/SectionReportGraph.vue';
import SectionReportList from '@/report/SectionReportList.vue';
import PreLoaderSection from '@/components/PreLoaderSection.vue';
import EmptyMessage from '@/components/EmptyMessage.vue';

export default {
  components: {
    SectionReportList,
    SectionReportGraph,
    PreLoaderSection,
    EmptyMessage,
  },
  data() {
    return {
      state: {
        graph: {
          isLoading: true,
          isError: null,
          isReady: false,
        },
        list: {
          isLoading: true,
          isError: null,
          isReady: false,
        },
      },
      graphData: {},
      topPrograms: [],
      updateIntervalRef: null,
      // sorted key may change after loaded data from get ccu api
      sortedKeys: ['totalViewCount', 'web', 'app', 'trueId'],
      intervalTime: null,
      currentSortType: null,
      currentStation: '',
    };
  },
  computed: {
    ...mapGetters(['userStationName']),
    graphFilters() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        stationName: this.stationName,
        range: this.range,
      };
    },
    startDate() {
      return _.get(this.$route, 'query.startDate', null);
    },
    endDate() {
      return _.get(this.$route, 'query.endDate', null);
    },
    stationName() {
      return _.get(this.$route, 'query.stationName', this.userStationName);
    },
    range() {
      return _.get(this.$route, 'query.range', null);
    },
    isEnabledUpdateGraphAndTopList() {
      const filter = this.graphFilters;
      if (filter.startDate || filter.endDate || filter.range === 'last7days' || filter.range === 'last30days') {
        return false;
      }
      return true;
    },
    isGraphDataLoading() {
      return this.state.graph.isLoading;
    },
    isTableDataLoading() {
      return this.state.list.isLoading;
    },
    isEmptyGraphData() {
      return !this.isGraphDataLoading && _.isEmpty(_.get(this.graphData, 'viewCounts', null));
    },
    isEmptyTopPrograms() {
      return !this.isTableDataLoading && _.isEmpty(_.get(this.topPrograms, 'topPrograms.datas', null));
    },
    isNoDataFound() {
      return !this.isGraphDataLoading && !this.isTableDataLoading && this.isEmptyGraphData && this.isEmptyTopPrograms;
    },
    isGraphReady() {
      return !this.isGraphDataLoading && this.state.graph.isReady;
    },
    isTableReady() {
      return !this.isTableDataLoading && this.state.list.isReady;
    },
  },
  watch: {
    graphFilters() {
      if (this.graphFilters.stationName === this.userStationName) {
        this.getGraphData(this.graphFilters);
        this.getTopProgramData(this.graphFilters);
        this.startUpdateInterval();
      }
    },
  },
  async created() {
    let filter = this.graphFilters;
    if (!this.stationName) {
      filter = {
        ...filter,
        stationName: this.userStationName,
      };
    }
    this.startUpdateInterval();

    await this.getGraphData(filter);
    await this.getTopProgramData(filter);
  },
  methods: {
    startUpdateInterval() {
      if (this.updateIntervalRef) {
        clearInterval(this.updateIntervalRef);
      }

      this.updateIntervalRef = setInterval(async () => {
        if (this.isEnabledUpdateGraphAndTopList) {
          await this.getGraphData(this.graphFilters, true);
          await this.getTopProgramData(this.graphFilters, true);
        }
      }, 60000);
    },
    async getGraphData(filter, isRefreshing = false) {
      if (!isRefreshing) {
        this.state.graph.isReady = false;
        this.state.graph.isLoading = true;
        this.graphData = null;
      }

      try {
        const response = await loadGraphData(filter);
        this.graphData = response.data;
        this.intervalTime = response.data.interval * 1000;
        this.state.graph.isLoading = false;
      } catch (error) {
        this.state.graph.isError = _.get(error, 'response', error);
        this.state.graph.isLoading = false;
      }
    },
    async getTopProgramData(filter, isRefreshing = false) {
      if (!isRefreshing) {
        this.state.list.isLoading = true;
        this.state.list.isReady = false;
        this.topPrograms = null;
      }

      try {
        const response = await loadTopList(filter);
        this.topPrograms = response.data;
        this.state.list.isLoading = false;
      } catch (error) {
        this.state.list.isError = _.get(error, 'response', error);
        this.state.list.isLoading = false;
      }
    },
    onGraphReady() {
      this.state.graph.isReady = true;
    },
    onTableReady() {
      this.state.list.isReady = true;
    },
  },
  beforeDestroy() {
    clearInterval(this.updateIntervalRef);
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/page.scss';

</style>
