/* eslint-disable import/prefer-default-export */
const getPlatformColorCode = (name) => {
  const platform = name.toLowerCase();
  switch (platform) {
    case 'web':
      return '#73E600';

    case 'app':
      return '#EB8D00';

    case 'trueid':
      return '#D80000';

    default:
      return 'transparent';
  }
};

export {
  getPlatformColorCode,
};
